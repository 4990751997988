<template>
  <div
    class="chip"
    :class="{
      'chip_filled': selectedOption,
    }"
  >
    <button class="chip__button" type="button" ref="button">
      <div class="chip__value" :title="selectedOption ? name : null">
        {{ selectedOption ? selectedOption.name : name }}
      </div>
      <div class="chip__close chip__icon" v-show="selectedOption" @click.stop="clear">
        <Icon name="close-circle-s" size="m"></Icon>
      </div>
      <div class="chip__caret chip__icon" v-show="!selectedOption">
        <Icon name="down" size="m"></Icon>
      </div>
    </button>
    <Dropdown
      ref="dropdown"
      trigger="button"
      class="chip__dropdown"
      :class="{
        'chip__dropdown_searchable': searchable,
      }"
      hide-overlay
      auto-width
      strategy="fixed"
      @toggle="reset"
    >
      <template v-slot:items>
        <div
          @click.stop=""
        >
          <div class="chip__search" v-if="searchable">
            <Input
              ref="search"
              v-model.trim="search"
              icon-left="search"
              :placeholder="$t('general.search') + '...'"
              no-border
              clearable
            ></Input>
          </div>
          <div class="chip__tip" v-if="!options.length">
            {{ $t('general.loading') }}...
          </div>
          <div class="chip__tip" v-else v-show="search && !filteredOptions.length">
            {{ $t('general.not_found_title') }}
          </div>
          <div class="chip-options">
            <FilterItem
              v-for="option in filteredOptions"
              :key="option.id"
              v-model="internalValue"
              :text="option.name"
              :name="name"
              :value="option.id"
              type="radio"
              @change.native="$emit('change', internalValue);"
            ></FilterItem>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Input from '@rr-component-library/input/src/main';
import FilterItem from '@/components/FilterItem/FilterItem.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';

export default {
  name: 'Chip',
  components: {
    Input,
    FilterItem,
    Dropdown,
  },
  model: {
    prop: 'value',
  },
  props: {
    value: {
      default: '',
    },
    name: String,
    options: Array,
    icon: String,
    searchable: Boolean,
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectedOption() {
      return this.options.find((o) => o.id === this.internalValue);
    },
    filteredOptions() {
      if (!this.search) {
        return this.options;
      }
      return this.options.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  methods: {
    clear() {
      this.internalValue = null;
      this.$emit('change', null);
    },
    reset(opened) {
      if (opened && this.searchable) {
        this.search = null;
        setTimeout(() => {
          this.$refs.search.$el.querySelector('input')
            .focus();
        }, 200);
      }
    },
  },
  watch: {
    internalValue() {
      this.$refs.dropdown.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Chip";
</style>
