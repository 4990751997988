<template>
  <div
    class="radio-accordion"
    :class="{
      'radio-accordion_checked': checked,
    }"
  >
    <label class="radio-accordion__header">
      <input v-model="internalValue" type="radio" :value="value">
      <div class="radio-accordion__img" v-if="icon || $slots.img">
        <Icon v-if="icon" :name="icon" size="xl"></Icon>
        <slot name="img"></slot>
      </div>
      <div class="radio-accordion__title" v-html="title"></div>
      <div class="radio-accordion__marker">
        <Icon :name="checked ? 'check-circle-s' : 'unchecked'" size="xl"></Icon>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioAccordion',
  model: {
    prop: 'inputModel',
  },
  props: {
    inputModel: {
      default: '',
    },
    value: {
      default: '',
    },
    icon: String,
    title: String,
  },
  computed: {
    checked() {
      return this.internalValue === this.value;
    },
    internalValue: {
      get() {
        return this.inputModel;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./RadioAccordion";
</style>
