<template>
  <div class="results-filters" v-if="isLoaded">
    <div
      class="results-filters__item"
      v-show="hasActiveFilters"
    >
      <Button
        class="results-filters__reset"
        variant="ghost"
        size="sm"
        icon-left="undo"
        :text="$t('results_filters.reset_all')"
        mobile
        @click="onReset"
      ></Button>
    </div>
    <div
      class="results-filters__item"
      v-for="(filter, key) in availableFilters"
      :key="key"
    >
      <Chip
        :ref="`filter-${key}`"
        v-model="filter.val"
        :name="filter.name"
        :options="filter.options"
        v-show="filter.options"
        @change="onFilterChange"
        :searchable="filter.searchable"
      >
      </Chip>
    </div>
  </div>
</template>

<script>
import { ResultStatus } from '@/enums';
import Chip from '@/components/Chip/Chip.vue';

export default {
  name: 'BroadcastFilters',
  components: {
    Chip,
  },
  props: {
    isRelay: Boolean,
  },
  data() {
    return {
      ResultStatus,
      filters: {
        gender: {
          name: this.$t('results_filters.gender'),
          val: null,
          options: [],
        },
        age: {
          name: this.$t('results_filters.age'),
          val: null,
          options: [],
          searchable: true,
        },
        category: {
          name: this.$t('results_filters.category'),
          val: null,
          options: [],
          optionsInit: [],
          searchable: true,
        },
        status: {
          name: this.$t('results_filters.status'),
          val: null,
          options: [
            { id: 'DSQ', name: this.$t('results_filters.status_dsq') },
            { id: 'DNS', name: this.$t('results_filters.status_dns') },
            { id: 'DNF', name: this.$t('results_filters.status_dnf') },
            { id: 'Finish', name: this.$t('results_filters.status_finish') },
          ],
        },
        club: {
          name: this.$t('results_filters.club'),
          val: null,
          options: [],
          searchable: true,
        },
        city: {
          name: this.$t('results_filters.city'),
          val: null,
          options: [],
          searchable: true,
        },
      },
      availableFilters: {},
      isLoaded: false,
      filterGenderAge: [],
      isAvailableChange: true,
    };
  },
  computed: {
    hasActiveFilters() {
      return Object.keys(this.availableFilters).some((key) => this.availableFilters[key].val);
    },
  },
  watch: {
    'availableFilters.gender.val': function changeGender(valNew, valOld) {
      if (valOld !== valNew) {
        if (!valNew && (this.filters.age.val || this.filters.category.val)) {
          this.isAvailableChange = false;
        }
        if (!this.isRelay) {
          this.changeFilterAgeOptions();
        } else {
          this.changeFilterCategoryOptions();
        }
      }
    },
  },
  methods: {
    onFilterChange() {
      this.$nextTick(() => {
        if (this.isAvailableChange) {
          this.setQuery();
          this.$emit('change');
        }
        this.isAvailableChange = true;
      });
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    },
    reset() {
      const filters = this.availableFilters;
      Object.keys(filters)
        .forEach((key) => {
          filters[key].val = null;
        });
      this.setQuery();
    },
    setQuery() {
      const filters = this.availableFilters;
      const query = { ...this.$route.query };
      Object.keys(filters)
        .forEach((key) => {
          query[key] = filters[key].val || undefined;
        });
      this.$router.replace({ query })
        .catch(() => {
        });
    },
    parseQuery() {
      const filters = this.availableFilters;
      const { query } = this.$route;
      Object.keys(filters).forEach((q) => {
        const option = query[q]
          ? filters[q].options.find((o) => o.id === query[q])
          : null;
        if (option) {
          filters[q].val = query[q];
        }
      });
    },
    getFilters() {
      const f = this.availableFilters;
      const resultStatus = this.ResultStatus[f.status?.val];
      if (!this.isRelay) {
        const data = {
          clubCode: f.club?.val || undefined,
          city: f.city?.val || undefined,
          genderAgeNominationId: f.category?.val || undefined,
          resultStatus: resultStatus != null ? resultStatus : undefined,
          genderNominationId: f.gender?.val || undefined,
          ageNominationId: f.age?.val || undefined,
        };
        return data;
      }
      return {
        nominationId: f.category?.val || undefined,
        genderNominationId: f.gender?.val || undefined,
        ageNominationId: f.age?.val || undefined,
        clubCode: f.club?.val || undefined,
        city: f.city?.val || undefined,
        resultStatus: resultStatus != null ? resultStatus : undefined,
      };
    },
    mapOptions(options, idKey, nameKey) {
      if (!options || !options.length) {
        return [];
      }
      return options.map((o) => (
        typeof o === 'object'
          ? {
            id: o[idKey || 'id'],
            name: o[nameKey || 'name'],
            code: o[idKey || 'code'],
          }
          : {
            id: o,
            name: o,
          }
      ));
    },
    setData(data) {
      this.availableFilters = {};
      if (!data) {
        this.filters.gender.options = [];
        this.filters.age.options = [];
        this.filters.category.options = [];
        this.filters.city.options = [];
        this.filters.club.options = [];
      } else {
        this.filters.gender.options = !this.isRelay ? this.mapOptions(data.genderNominations) : [];
        // this.filters.age.options = !this.isRelay ? this.mapOptions(data.ageNominations) : [];
        this.filters.age.options = [];
        this.filters.age.optionsInit = this.filters.age.options;
        this.filters.category.options = !this.isRelay ? this.mapOptions(data.genderAgeNominations) : [];
        this.filters.category.optionsInit = this.filters.category.options;
        this.filters.city.options = this.mapOptions(data.cities);
        this.filters.club.options = this.mapOptions(data.raceClubs, 'code', 'title');
        this.filters.club.options = this.filters.club.options.map((option) => {
          if (!option.name) {
            // eslint-disable-next-line no-param-reassign
            option.name = option.code;
          }

          return option;
        });
        this.filterGenderAge = this.mapOptions(data.genderAgeNominations);
      }

      Object.keys(this.filters)
        .forEach((key) => {
          const filter = this.filters[key];
          filter.searchable = filter.options.length > 5;
          if (filter.options.length > 0) {
            this.availableFilters[key] = filter;
          }
        });

      this.parseQuery();
      this.isLoaded = true;
    },
    changeFilterAgeOptions() {
      const currentGender = this.availableFilters.gender.options.find((item) => item.id === this.availableFilters.gender.val);
      if (this.$refs['filter-age'] && this.filters.age.val) {
        this.$refs['filter-age'][0].clear();
      }
      if (currentGender && this.filters.age.options.length) {
        const [, currentGenderCode] = currentGender.code.split('_');

        const optionsFilter = this.filterGenderAge.filter((item) => {
          const gander = item.code.split('_')[1];
          return gander === currentGenderCode;
        });

        this.filters.age.options = optionsFilter;
      } else {
        this.filters.age.options = this.filters.age.optionsInit;
      }
    },
    changeFilterCategoryOptions() {
      const currentGender = this.availableFilters.gender.options.find((item) => item.id === this.availableFilters.gender.val);
      if (this.$refs['filter-category'] && this.filters.category.val) {
        this.$refs['filter-category'][0].clear();
      }
      if (currentGender && this.filters.category.options.length) {
        const [, currentGenderCode] = currentGender.code.split('_');

        const optionsFilter = this.filterGenderAge.filter((item) => {
          const gander = item.code.split('_')[1];
          return gander === currentGenderCode;
        });

        this.filters.category.options = optionsFilter;
      } else {
        this.filters.category.options = this.filters.category.optionsInit;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./ResultsFilters";
</style>
