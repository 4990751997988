<template>
  <label
    class="filter-item"
    :class="{
      'filter-item_checked': checked,
    }"
  >
    <input
      v-model="internalValue"
      :type="type"
      :value="value"
      :name="name"
      v-on="inputListeners"
    >
    <span
      class="filter-item__marker"
      :class="[`filter-item__marker_${type}`]"
    >
      <Icon name="check-bold"></Icon>
    </span>
    <span class="filter-item__text" v-html="text"></span>
  </label>
</template>

<script>
export default {
  name: 'FilterItem',
  designVersion: '2.0.1',
  model: {
    prop: 'inputModel',
  },
  props: {
    inputModel: {
      default: '',
    },
    value: {
      default: '',
    },
    text: String,
    name: String,
    type: {
      type: String,
      required: true,
      validator: (value) => ['radio', 'checkbox'].indexOf(value) !== -1,
    },
    reverse: Boolean,
  },
  computed: {
    multiple() {
      return this.internalValue && typeof this.internalValue === 'object';
    },
    checked() {
      const { type } = this;
      if (type === 'checkbox' && this.multiple) {
        return this.internalValue.indexOf(this.value) !== -1;
      }
      if (type === 'radio') {
        return this.internalValue === this.value;
      }
      return this.internalValue;
    },
    internalValue: {
      get() {
        return this.inputModel;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    inputListeners() {
      const vm = this;
      return {
        ...this.$listeners,
        input(event) {
          vm.$emit('input', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./FilterItem";
</style>
