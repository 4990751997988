<template>
  <Modal
    ref="modal"
    name="videoFinish"
    :title="$t('video_finish.title')"
    @afterClose="afterModalClose"
  >
    <div class="rr-modal__item">
      <div class="video-finish-slider">
        <div ref="slider" class="video-finish-slider__container">
          <div class="video-finish-slider__wrapper swiper-wrapper">
            <div
              v-for="(video, i) in videos"
              :key="i"
              class="video-finish-slider__slide swiper-slide"
            >
              <div class="video-slide">
                <div class="video-slide__title">
                  {{ getTitle(video) }}
                </div>
                <video
                  controls
                  :poster="require(theme === 'rr'
                    ? '@/assets/img/video-poster-rr.jpeg'
                    : '@/assets/img/video-poster.jpg'
                  )"
                >
                  <source
                    :src="getVideoUrl(video.url)"
                    type="video/mp4"
                  >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div class="swiper-pagination" v-show="videos.length > 1"></div>
        </div>
        <div v-show="videos.length > 1">
          <Button
            ref="prev"
            class="video-finish-slider__prev"
            variant="secondary"
            shape="circle"
            size="lg"
            icon-left="left"
          ></Button>
          <Button
            ref="next"
            class="video-finish-slider__next"
            variant="secondary"
            shape="circle"
            size="lg"
            icon-left="right"
          ></Button>
        </div>
      </div>
    </div>
    <div class="rr-modal__item">
      <Alert
        state="warning"
        :title="$t('video_finish.delete_warning_title')"
        :text="$t('video_finish.delete_warning_text')"
        noAction
      ></Alert>
    </div>
    <div class="rr-modal__item">
      <Button
        variant="primary"
        size="lg"
        icon-left="download"
        :text="$t('video_finish.download_video')"
        :href="currentVideoUrl"
        target="_blank"
      ></Button>
    </div>
  </Modal>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal/Modal.vue';

Swiper.use([Navigation, Pagination]);

export default {
  name: 'VideoFinish',
  components: {
    Modal,
  },
  data() {
    return {
      swiper: null,
      videos: [],
      currentVideoUrl: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeUrls']),
  },
  methods: {
    init() {
      if (this.swiper) {
        return;
      }
      const vm = this;
      this.swiper = new Swiper(this.$refs.slider, {
        simulateTouch: false,
        spaceBetween: 12,
        navigation: {
          nextEl: this.$refs.next.$el,
          prevEl: this.$refs.prev.$el,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        on: {
          afterInit() {
            setTimeout(vm.setCurrentVideoUrl);
          },
          slideChange() {
            vm.onSlideChange();
          },
        },
      });
    },
    open(videos) {
      this.videos = videos || [];
      this.init();
      this.$refs.modal.open();
    },
    onSlideChange() {
      this.setCurrentVideoUrl();
      const prevVideo = this.swiper.slides[this.swiper.previousIndex]?.querySelector('video');
      if (prevVideo) {
        this.stopVideo(prevVideo);
      }
    },
    setCurrentVideoUrl() {
      const index = this.swiper.activeIndex || 0;
      this.currentVideoUrl = this.getVideoUrl(this.videos[index]?.url);
    },
    stopVideo(video) {
      if (!video.paused) {
        video.pause();
        // eslint-disable-next-line no-param-reassign
        video.currentTime = 0;
      }
    },
    getTitle(data) {
      if (data.orderIndex !== 1 && data.orderIndex !== 999999) {
        return null;
      }

      return data.orderIndex === 1
        ? this.$t('video_finish.start_camera', [data.order])
        : this.$t('video_finish.finish_camera', [data.order]);
    },
    getVideoUrl(url) {
      if (url && this.themeUrls.Video) {
        return url
          .replace('https://video.russiarunning.com', this.themeUrls.Video)
          .replace('https://videotest.russiarunning.com', this.themeUrls.Video);
      }
      return url;
    },
    afterModalClose() {
      this.videos = [];
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./VideoFinish";
</style>
