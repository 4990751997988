<template>
  <Modal
    name="protocolModal"
    :title="isFinal ? $t('protocol.final_protocol') : $t('protocol.interim_protocol')"
    :subtitle="dated"
    @open="setDefaultValue()"
  >
    <div class="rr-modal__item">
      <div class="text-left" style="margin-bottom: 12px;">
        <b>{{ $t('protocol.protocol_template') }}:</b>
      </div>
      <RadioAccordion
        v-for="item in templates"
        :key="item.value"
        v-model="value"
        :title="getTitle(item)"
        :value="item.value"
      >
      </RadioAccordion>
    </div>
    <Alert
      v-if="textAlert || titleAlert"
      state="warning"
      :title="titleAlert"
      :text="textAlert"
      noAction
      class="rr-modal__item"
    />
    <div class="rr-modal__item">
      <Button
        variant="primary"
        size="lg"
        icon-left="download"
        text="XLS"
        :href="xlsLink"
        target="_blank"
      ></Button>
      <Button
        class="ml-8"
        variant="primary"
        size="lg"
        icon-left="download"
        text="PDF"
        :href="pdfLink"
        target="_blank"
      ></Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal/Modal.vue';
import RadioAccordion from '@/components/RadioAccordion/RadioAccordion.vue';

export default {
  name: 'ResultsProtocol',
  components: {
    Modal,
    RadioAccordion,
  },
  props: {
    raceId: String,
    templates: {
      type: Array,
      default: () => [],
    },
    calculatedDate: String,
    isFinal: Boolean,
    timingType: Number,
  },
  data() {
    return {
      value: null,
      xlsLink: null,
      pdfLink: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeUrls', 'themeData']),
    currentTemplate() {
      return this.templates.find((o) => o.value === this.value);
    },
    dated() {
      if (!this.calculatedDate) {
        return null;
      }

      const localizedDate = new Date(this.calculatedDate)
        .toLocaleDateString(this.$lang, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
        .replace('г.', '');

      return `${this.$t('general.dated').toLowerCase()} ${localizedDate}`;
    },
    titleAlert() {
      switch (this.timingType) {
        case 10:
          return this.$t('protocol.alert_title_timing_external');

        default:
          return '';
      }
    },
    tenant() {
      return this.themeData?.tenant ? '' : 'RussiaRunning Timing';
    },
    textAlert() {
      switch (this.timingType) {
        case 0:
          return this.$t('protocol.alert_text_timing_bibtag');
        case 1:
          return this.$t('protocol.alert_text_timing_prochip');
        case 10:
          return this.$t('protocol.alert_text_timing_external');

        default:
          return '';
      }
    },
  },
  methods: {
    getTitle(template) {
      const code = this.theme === 'rr'
        ? template.templateCode
        : template.templateCode?.replace('RussiaRunning', '');
      return `${code || ''} ${template.name}`.trim();
    },
    setDefaultValue() {
      this.value = this.templates[0] && this.templates[0].value;
    },
  },
  watch: {
    currentTemplate(template) {
      const link = template
        ? `${this.themeUrls.Events}/Results/Services/DownloadProtocol?raceId=${this.raceId}&templateCode=${template.templateCode}&fileExtension={0}&culture=${template.culture}`
        : null;
      this.pdfLink = link && link.replace('{0}', 'pdf');
      this.xlsLink = link && link.replace('{0}', 'xls');
    },
  },
};
</script>
